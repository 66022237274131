<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <el-card>
          <div class='table-search'>
            <el-form :model='searchCondition' inline size='mini'>
              <el-form-item label='来源'>
                <el-select placeholder='用户来源' v-model='searchCondition.type' clearable>
                  <el-option :label='v' :value='k' v-for='(v,k) in typeMap' :key='k'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='昵称'>
               <el-input placeholder='昵称' v-model='searchCondition.nickname' clearable></el-input>
              </el-form-item>
              <el-form-item label='性别'>
                <el-select placeholder='性别' v-model='searchCondition.gender' clearable style='width: 100px'>
                  <el-option :label='v' :value='k' v-for='(v,k) in genderMap' :key='k'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='创建时间：'>
                <el-date-picker
                  v-model='searchCondition.date_range'
                  type='daterange'
                  align='right'
                  unlink-panels
                  range-separator='至'
                  start-placeholder='开始日期'
                  end-placeholder='结束日期'
                  value-format='yyyy-MM-dd'
                  :picker-options='pickerOptions'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class='default-table'>
            <!--    渲染表格-->
            <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                     :loading='loadingStatus' @sort-change='changeTableSort' border>
              <el-table-column label='操作' width='100' align='center'>
                <template slot-scope='{row}'>
                  <el-button size='mini' type='text' icon='el-icon-view' @click='handleView(row)'>详情</el-button>
                </template>
              </el-table-column>
            </m-table>
            <!--    渲染分页-->
            <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                          @pagination='getList' />
          </div>
        </el-card>
        <div>
          <el-divider content-position='left'>趋势图</el-divider>
          <WxUserChart></WxUserChart>
        </div>
      </div>
    </page-header-layout>
    <!-- 操作日志详细 -->
    <el-dialog title='详情' :visible.sync='open' width='700px' append-to-body>
      <el-form ref='form' :model='form' size='mini'>
        <el-row>
          <el-col :span='24'>
            <el-form-item label=''>
              <el-input :autosize='{ minRows: 15, maxRows: 40}' type='textarea'
                        :value='jsonData'></el-input>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='open = false'>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import WxUserChart from '@/pages/statistics/WxUserChart'

export default {
  name: 'WechatUserList',
  components: { WxUserChart, PageHeaderLayout },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      form: {},
      loadingStatus: false,
      orderSort: { id: 'desc' },
      searchCondition: { dept_name: null, dpt_id: null, name: null, is_leave: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '来源',
          value: 'type_alias',
          field: 'type',
          width: 80,
          sortable: 'custom'
        },
        {
          title: '头像',
          value: 'avatar',
          type: 'image',
          width: 50
        },
        {
          title: '用户昵称',
          value: 'nickname',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '性别',
          value: 'gender_alias',
          width: 80
        },
        {
          title: '语言',
          value: 'language',
          width: 120
        }, {
          title: '国家',
          value: 'country',
          width: 120
        }, {
          title: '省',
          value: 'province',
          width: 80
        }, {
          title: '市',
          value: 'city',
          width: 80
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      leaveStatusMap: { 'Y': '离职', 'N': '在职' },
      typeMap: { '1': 'KOC公众号', '2': '缇苏招商小程序' },
      genderMap: { '1': '男', '2': '女' },
      currRow: {},
      dialogVisible: false,
      real_count: 0,
      num_job: 0,
      num_leave: 0
    }
  },
  computed: {
    jsonData() {
      if (this.form)
        return JSON.stringify(this.form, null, 4)
      else
        return JSON.stringify({})
      // return this.formData.notify_msg || {}
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.gender) {
        condition.gender = this.searchCondition.gender
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getWxUserList(searchCondition)
      list.map(item => {
        item['type_alias'] = this.typeMap[item.type + '']
        item['gender_alias'] = this.genderMap[item.gender + ''] ? this.genderMap[item.gender + ''] : '保密'
      })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })

    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>