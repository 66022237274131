<template>
  <div>
    <div>
      <el-form size='medium' :model='searchCondition' inline>
        <el-form-item label=''>
          <el-select placeholder='用户来源' v-model='searchCondition.type' clearable>
            <el-option :label='v' :value='k' v-for='(v,k) in typeMap' :key='k'>{{ v }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model='searchCondition.checkedRadio' @change='radioChange'>
            <el-radio-button :label='item.value' v-for='(item,index) in radioOptions' :key='index'>{{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if='searchCondition.checkedRadio==="day"'>
          <el-date-picker
              v-model='searchCondition.day'
              align='right'
              type='date' @change='radioChange'
              placeholder='选择日期'
              value-format='yyyy-MM-dd'
              format='yyyy年MM月dd日'
              :picker-options='pickerOptions'>
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if='searchCondition.checkedRadio==="month"'>
          <el-date-picker
              v-model='searchCondition.month'
              type='month'
              value-format='yyyy-MM'
              @change='radioChange'
              format='yyyy年MM月'
              placeholder='选择年月'>
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if='searchCondition.checkedRadio==="year"'>
          <el-date-picker
              v-model='searchCondition.year'
              type='year'
              value-format='yyyy'
              @change='radioChange'
              format='yyyy年'
              placeholder='选择年份'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='radioChange' :disabled='loading' :loading='loading'>查询
          </el-button>
        </el-form-item>
      </el-form>
      <div>
        <div>
          <pie-chart :chart-option='chartOption'></pie-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from '@/pages/dashboard/PieChart'

export default {
  name: 'WxUserChart',
  components: { PieChart },
  data() {
    return {
      loading: false,
      // 日期单选
      checkedRadio: 'day',
      radioOptions: [
        { value: 'day', label: '按日查看' },
        { value: 'month', label: '按月查看' },
        { value: 'year', label: '按年查看' }
      ],
      typeMap: { '1': 'KOC公众号', '2': '缇苏招商小程序' },
      searchCondition: { type: null, checkedRadio: 'day', day: null, year: null, month: null },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      chartOption: {},
      option: {
        title: {
          text: '微信用户增量',
          top: 0,
          left: '40%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: { //轴文字标签
            show: true,
            color: '#ff3176'
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#ff3176' // 修改y轴线条颜色为绿色
            }
          }
        },
        series: [
        ]
      }
    }
  },
  methods: {
    radioChange() {
      // this.initChart()
      this.getChartData()
    },
    initChart() {
      //图表
      this.chartOption = { ...this.option }
      // this.getChartData()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.checkedRadio) {
        if (this.searchCondition.checkedRadio === 'day') {
          if (this.searchCondition.day) {
            condition.day = this.searchCondition.day
          }
        }
        if (this.searchCondition.checkedRadio === 'month') {
          if (this.searchCondition.month) {
            condition.month = this.searchCondition.month
          }
        }
        if (this.searchCondition.checkedRadio === 'year') {
          if (this.searchCondition.year) {
            condition.year = this.searchCondition.year
          }
        }
        condition.checkedRadio = this.searchCondition.checkedRadio
      }
      //招商小程序
      if (this.searchCondition.type) {
        condition['type'] = this.searchCondition.type
      }

      return condition
    },
    async getChartData() {
      this.loading = true
      //请求数据
      let searchCondition = this.handleSearchCondition()
      let { option } = await this.$api.getWxUserChartData(searchCondition)
      // {
      //   name: 'Email',
      //       type: 'line',
      //     stack: 'Total',
      //     data: [120, 132, 101, 134, 90, 230, 210]
      // },
      //筛选条件
      // this.chartOption.title.subtext = option.subtext
      let newData = {
        name: '新增',
        data: option?.data,
        type: 'line',
        smooth: true,
        label: {
          show: true,
          position: 'top'
        },
        itemStyle: {
          color: '#ff5a91'
        }
      }
      this.option.series = [newData]
      this.option.xAxis.data = option?.xAxis || []
      // this.option.series
      this.initChart()
      this.loading = false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getChartData()
    })
  },
  created() {
    const date = new Date()
    this.searchCondition.year = date.getFullYear() + ''
    this.searchCondition.month = date.getFullYear() + '-' + (date.getMonth() + 1)
    this.searchCondition.day = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.searchCondition.type = '2'

  }

}
</script>

<style scoped>

</style>
